export const signupInitialState = {
  signupReducer: {
    accountCreated: false,
    formInfo: {
      company: {
        id: null, comp_name: ''
      },
      employee_families: '',
      firstname: '',
      paternal_lastname: '',
      maternal_lastname: '',
      user_phone: '',
      calling_country_code: '+52',
      email: '',
      password: '',
      internal_company_id: ''
    },
    errorsInForm: {
      company: '',
      employee_families: '',
      firstname: '',
      paternal_lastname: '',
      maternal_lastname: '',
      user_phone: '',
      calling_country_code: '',
      email: '',
      password: ''
    },
    touchedFields: {},
    companiesAtDropdown: [],
    customInputs: [],
    companyConfig: {},
    userTypes: []
  }
};
