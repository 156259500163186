import {
  DUPLICATED_ENTRY,
  WILL_CLOSE_DIALOG,
  WILL_DELETE_APPLICATION
} from '../action_types';

export const applymentsReducer = (state, action) => {
  switch (action?.type) {
    case WILL_CLOSE_DIALOG:
      return {
        ...state,
        isDialogOpen: false
      };
    case WILL_DELETE_APPLICATION:
      return {
        ...state,
        willDeleteApplymentById: action.payload.willDeleteApplymentById,
        isDialogOpen: true
      };
    case DUPLICATED_ENTRY:
      return {
        ...state,
        duplicatedEntry: action.payload
      };
    default:
      return state;
  };
};