import {
  SET_ACCOUNT_CREATED,
  SET_COMPANIES_DROPDOWN,
  SET_COMPANY_CONFIG,
  SET_CUSTOM_INPUTS,
  SET_ERROR_AT_SIGNUP_FIELD,
  SET_SIGNUP_FIELD,
  SET_TOUCHED_FIELD,
  SET_USER_TYPES
} from '../action_types';

export const signupReducer = (state, action) => {
  switch (action?.type) {
    case SET_COMPANIES_DROPDOWN:
      const allCompanies = action?.payload
      const companiesIncludedList = allCompanies.filter((company) => {
        if (company?.config?.showOnSignUp) return company;
      })
      return {
        ...state,
        companiesAtDropdown: [...companiesIncludedList]
      }
    case SET_USER_TYPES:
      return {
        ...state,
        userTypes: action.payload
      }
    case SET_SIGNUP_FIELD:
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          [action.payload.id]: action.payload.value,
        }
      };
    case SET_ERROR_AT_SIGNUP_FIELD:
      return {
        ...state,
        errorsInForm: {
          ...state.errorsInForm,
          [action.payload.id]: action.payload.value,
        }
      };
    case SET_TOUCHED_FIELD: 
      return {
        ...state,
        touchedFields: {
          ...state.touchedFields,
          [action.payload.field]: true,
        }
      }
    case SET_CUSTOM_INPUTS:
      return {
        ...state,
        customInputs: action.payload
      }
    case SET_COMPANY_CONFIG:
      return {
        ...state,
        companyConfig: action.payload
      }
    case SET_ACCOUNT_CREATED:
      return {
        ...state,
        accountCreated: true
      }
    default:
      return state;
  };
}; 