import {
  ADD_SAVED_COURSE,
  ADD_SUBJECT_AREA_TAGS_FILTER,
  CHANGE_PAGE,
  DELETE_FILTER_CHECKBOX,
  DELETE_SAVED_COURSE,
  DELETE_SUBJECT_AREA_TAGS_FILTER,
  REMOVE_REDIRECT_FROM_INDEX,
  RESET_ALL_FILTERS,
  RESET_RECURRING_DATE,
  RESET_REGISTER_DATE,
  RESET_START_DATE,
  RESET_STARTED_SAVING,
  SELECT_COURSE,
  SET_COURSE_TYPE_FROM_INDEX,
  SET_COURSE_TYPES,
  SET_COURSES,
  SET_COURSES_COUNT,
  SET_COURSES_LOADING,
  SET_FAVORITE_FILTERED_COURSES,
  SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES,
  SET_FAVORITE_SEARCHBAR_QUERY,
  SET_FILTER_BY_DATE,
  SET_FILTER_BY_START_DATE,
  SET_FILTER_CHECKBOX,
  SET_FILTERED_COURSES,
  SET_FOUND_COURSES,
  SET_FOUND_COURSES_PAGE,
  SET_PAGINATION,
  SET_RECOMMENDED_COURSES,
  SET_RECOMMENDED_FILTERED_COURSES,
  SET_RECURRING_DATE,
  SET_SAVED_COURSES,
  SET_SEARCH_BAR_BY,
  SET_SEARCH_BAR_LOADING,
  SET_SEARCH_BAR_PAGINATION,
  SET_SEARCH_BAR_QUERY,
  SET_SEARCH_BAR_VALUE,
  SET_SUBJECT_AREA_TAGS,
  SET_UNIVERSITIES,
  TOGGLE_MOBILE_FILTERS,
} from '../action_types';

export const coursesReducer = (state, action) => {
  switch (action?.type) {
    case TOGGLE_MOBILE_FILTERS:
      return {
        ...state,
        areMobileFiltersOpen: !state.areMobileFiltersOpen,
      };
    case SET_COURSES:
      return { ...state, coursesInStore: action.payload }; 
    case RESET_STARTED_SAVING: 
      return { ...state, startedSaving: false };
    case SET_SAVED_COURSES:
      return { ...state, savedCourses: action.payload };
   case SET_RECOMMENDED_COURSES:
      return { ...state, recommendedCourses: action.payload };   
    case ADD_SAVED_COURSE:
      return {
        ...state,
        startedSaving: true,
        savedCourses: [...state.savedCourses, action.payload]
      };
    case DELETE_SAVED_COURSE:
      return {
        ...state,
        startedSaving: true,
        savedCourses: [
          ...state.savedCourses.filter(savedCourse => savedCourse.course_id !== action.payload.course_id)
        ]
      };
    case SET_FILTERED_COURSES:
      return { ...state, filteredCourses: action.payload };
    case SET_FILTER_CHECKBOX:
      const newFilterRules = {
        ...state['filterRules'],
        [action.payload.filterType]: [
          ...state['filterRules'][action.payload.filterType],
          action.payload.id
        ],
      };
      return { ...state, filterRules: { ...newFilterRules } };
    case SET_PAGINATION:
      const pagination = { ...state['pagination'], numberOfPages: action.payload.numberOfPages, query: action.payload.query };
      return { ...state, pagination: { ...pagination }, totalCourses: action.payload.totalCourses };
    case DELETE_FILTER_CHECKBOX:
      const deletedFilterRules = {
        ...state['filterRules'],
        [action.payload.filterType]: [
          ...state['filterRules'][action.payload.filterType].filter(filter => filter !== action.payload.id)
        ],
      }
      return { ...state, filterRules: { ...deletedFilterRules } };
    case SET_UNIVERSITIES:
      const newUniversities = { ...state, universities: action.payload };
      return newUniversities;
    case SET_COURSE_TYPES:
      const newCourseTypes = { ...state, courseTypes: action.payload };
      return newCourseTypes;
    case SELECT_COURSE:
      return { ...state, selectedCourse: action.payload };
    case SET_COURSES_LOADING:
      return { ...state, coursesLoading: action.payload };
    case SET_SEARCH_BAR_QUERY:
      const newSearchBar = { ...state['searchBar'], query: action.payload };
      return { ...state, searchBar: { ...newSearchBar } };
    case SET_SEARCH_BAR_VALUE:
      return {
        ...state,
        searchBar: {
          ...state.searchBar,
          inputValue: action.payload
        }
      };
    case SET_SEARCH_BAR_PAGINATION:
      const newSearchBarPagination = { ...state['searchBar'], numberOfPages: action.payload.numberOfPages };
      return {
        ...state,
        searchBar: { ...newSearchBarPagination }
      };
    case SET_SEARCH_BAR_BY:
      const newSearchBarBy = { ...state['searchBar'], searchBy: action.payload };
      return { ...state, searchBar: { ...newSearchBarBy } };
    case SET_SEARCH_BAR_LOADING:
      const newSearchBarLoading = { ...state['searchBar'], loading: action.payload };
      return { ...state, searchBar: { ...newSearchBarLoading } };
    case SET_FOUND_COURSES:
      return {
        ...state,
        foundCourses: action.payload.data,
      };
    case SET_FOUND_COURSES_PAGE:
      const newPage = { ...state['searchBar'], page: action.payload };
      return {
        ...state,
        searchBar: { ...newPage }
      };
    case SET_FAVORITE_SEARCHBAR_QUERY:
      return {
        ...state,
        favoriteSearchBar: {
          ...state.favoriteSearchBar,
          query: action.payload
        }
      };
    case SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES:
      return {
        ...state,
        favoriteSearchBar: {
          ...state.favoriteSearchBar,
          numberOfPages: action.payload
        }
      };
    case SET_FAVORITE_FILTERED_COURSES:
      return { ...state, filteredSavedCourses: action.payload };
    case SET_RECOMMENDED_FILTERED_COURSES:
      return { ...state, filteredRecommendedCourses: action.payload };
    case SET_FILTER_BY_DATE:
      const newDateFilter = {
        ...state['filterByDate'],
        registrationDate: action.payload.registrationDate,
        registrationDateGte: action.payload.registrationDateGte,
        registrationDateLte: action.payload.registrationDateLte,
      };
      return {
        ...state,
        filterByDate: { ...newDateFilter }
      };
  
    case SET_FILTER_BY_START_DATE:
      const newStartDateFilter = {
        ...state['filterByCourseStart'],
        startDate: action.payload.startDate,
        startDateGte: action.payload.startDateGte,
        startDateLte: action.payload.startDateLte,
      };
      return {
        ...state,
        filterByCourseStart: { ...newStartDateFilter }
      };
    case SET_COURSES_COUNT:
      return {
        ...state,
        foundCoursesCount: action.payload.foundCoursesCount,
        totalPages: action.payload.totalPages
      };
    case CHANGE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case RESET_REGISTER_DATE:
      const resetRegisterDate = {
        ...state['filterByCourseStart'],
        registrationDate: '',
        registrationDateGte: '',
        registrationDateLte: '',
      };
      return {
        ...state,
        filterByDate: { ...resetRegisterDate }
      };
    case RESET_START_DATE:
      const resetStartDate = {
        ...state['filterByCourseStart'],
        startDate: '',
        startDateGte: '',
        startDateLte: '',
      };
      return {
        ...state,
        filterByCourseStart: { ...resetStartDate }
      };
    case SET_RECURRING_DATE:
      return {
        ...state,
        filterByRecurringDate: action.payload,
      };
    case RESET_RECURRING_DATE:
      return {
        ...state,
        filterByRecurringDate: '',
      };
    case SET_SUBJECT_AREA_TAGS:
      return {
        ...state,
        subjectAreaTags: action.payload,
      };
    case ADD_SUBJECT_AREA_TAGS_FILTER:
      return {
        ...state,
        filterBySubjectAreaTags: [
          ...state.filterBySubjectAreaTags,
          action.payload
        ]
      };
    case DELETE_SUBJECT_AREA_TAGS_FILTER:
      return {
        ...state,
        filterBySubjectAreaTags: [
          ...state.filterBySubjectAreaTags.filter(tagId => tagId !== action.payload)
        ]
      };
    case RESET_ALL_FILTERS:
      return {
        ...state,
          filterRules: {
            coursesTypes: [],
            universities: []
          },
          filterByRecurringDate: '',
          filterBySubjectAreaTags: []
      }
    case SET_COURSE_TYPE_FROM_INDEX:
      const courseTypesFromIndex = {
        ...state['filterRules'],
        'coursesTypes': [action.payload],
      };
      return { 
        ...state,
        redirectFromIndex: true,
        filterRules: { ...courseTypesFromIndex },
        filterByRecurringDate: '',
        filterBySubjectAreaTags: [],
      };
    case REMOVE_REDIRECT_FROM_INDEX:
      return {
        ...state,
        redirectFromIndex: false
      };
    default:
      return state;
  };
};