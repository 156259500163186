import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, Snackbar, CssBaseline, Link, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from 'formik';
import PropTypes from "prop-types";

import Copyright from "../components/Copyright";
import { LoginForm } from "../components/Forms/LoginForm";
import { schema } from "../components/Forms/loginFormik";
import LoadingLayout from "../components/LoadingLayout";
import LoginModal from "../components/LoginModal";
import { LogoLogin } from "../components/logo";
import { Section } from "../components/section";

import { isLoading, resetLoginState, setChangePasswordModal, setErrorAtLogin } from '../context/actions';
import { store } from '../context/store';
import { getLogoCompanyByqueryParams } from '../lib/collections';
import { removeCookies } from '../lib/cookies';
import { hash } from '../lib/hash';
import { handleLoginResponse, login } from '../lib/users';
import { theme as themeStyles } from "../styles/theme-constants";
import { trackLogOut } from '../tracking/users';
import { useRouter } from "next/router";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 350,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingTop: '1rem'
  },
  textInputs: {
    fontSize: '22px',
  },
  errorline: {
    color: themeStyles.error,
    marginTop: '0px'
  },
  label: {
    color: '#333',
    fontSize: '18px',
  },
  showPassword: {
    color: themeStyles.primary,
    fontSize: 'small'
  },
  myAlert: {
    position: "absolute",
    zIndex: "10",
  },
  mySnackbar: {
    width: "45%",
    bottom: "17%",
  },
  divider: {
    border: '1px solid #E0E0E0',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  linkButton:{
    color:themeStyles.primary,
    fontSize:'14px',
    fontWeight:'bold',
    border: 'none',
    display:'block',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    textAlign: 'right'
  },
  buttonSignUp:{
    backgroundColor: themeStyles.white,
    color:themeStyles.primary,
    fontSize:'14px',
    fontWeight:'bold',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    height: '52px',
    padding: '0 40px',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '18px',
    marginTop: '24px',
    fontWeight: '700',
    borderRadius: '5px',
    marginBottom: '24px',
    boxShadow:"rgba(0, 0, 0, 0.13) 0px 1px 1px",
    border:`1px solid ${themeStyles.primary}`,
    textTransform: 'none',
    '&:hover':{
      backgroundColor:themeStyles.primary,
      color:themeStyles.white
    }
  },
});

function SignIn(props) {
  const router = useRouter();
  const { state, dispatch } = useContext(store);
  const {
    authReducer: { error, showChangePasswordModal },
    applicationReducer: { loginProgress, isLoginSuccessfull },
  } = state;

  const { classes, redirect } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [isIE, setIsIE] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberme: false,
    },
    validationSchema: schema,
    onSubmit: handleLogin,
  });

  useEffect(() => {
    setIsIE(/MSIE|Trident/.test(window.navigator.userAgent));
    resetLoginState(dispatch);
  }, []);

  const handleClickShowPassword = () => setShowPassword(prev => !prev);
  
  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setIsIE(false);
    }
  };

  async function handleLogin(values) {
    sessionStorage.removeItem('time');
    localStorage.clear();

    isLoading(dispatch, { loading: true });
    setErrorAtLogin(dispatch, false);

    const loginInfo = {
      identifier: values.email.trim(),
      password: hash(values.password),
    };

    const response = await login(dispatch, loginInfo);
    handleLoginResponse(dispatch, {
      ...response,
      data: { ...response.data, maxAge: values.rememberme, redirect }
    }, loginInfo.identifier);
  }

  const closeModal = () => setChangePasswordModal(dispatch, false);

 
  const SignUpPrompt = () => (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <button className={classes.buttonSignUp} onClick={() => router.push('/signup')}>
          Crea tu cuenta aquí
        </button>
      </Grid>
    </Grid>
  );  

  const BrowserCompatibilityAlert = ({ isOpen, handleClose, classes }) => {
    return (
      <Snackbar className={classes.mySnackbar} open={isOpen} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" className={classes.myAlert} severity="info">
          Para conseguir la mejor experiencia de uso, accede a Vinco desde el navegador Google Chrome.
        </Alert>
      </Snackbar>
    );
  }

  const handleMouseDownPassword = () => setShowPassword(prev => !prev);

  return (
    <main className={classes.main}>
      <CssBaseline />
      <LoadingLayout isLoading={loginProgress} />
      <Section className={classes.paper}>
        <LogoLogin noMargin logoCompany={props.logo}/>
        <Typography component="h1" variant="h5">
          Entra a tu cuenta
        </Typography>
        <LoginForm
          classes={classes}
          error={error}
          isLoginSuccessfull={isLoginSuccessfull}
          loginProgress={loginProgress}
          formik={formik}
          showPassword={showPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        <Divider variant="fullWidth" className={classes.divider} />
        <Typography component="p" variant="p">¿No tienes cuenta?</Typography>
        <SignUpPrompt />
        <LoginModal 
          open={showChangePasswordModal} 
          closeModal={closeModal} 
          username={formik.values.email} 
        />
        <BrowserCompatibilityAlert 
          isOpen={isIE}
          handleClose={handleClose}
          classes={classes}
        />
      </Section>
      <Copyright />
    </main>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export async function getServerSideProps(ctx) {
  const { query } = ctx;

  // Debug: Log initial query parameters
  console.log("Incoming query parameters:", query);

  // Remove cookies
  removeCookies(ctx);

  // Define the SQL injection regex
  const sqlInjectionPattern = /(['"=;]|--)+/;

  // Check for SQL injection patterns or invalid parameters
  for (const [key, value] of Object.entries(query)) {
    // Validate key and value
    const isValidKey = ['ref', 'redirect'].includes(key); // Allow only valid keys
    if (!isValidKey || sqlInjectionPattern.test(String(value))) {
      console.warn(`Blocked potential SQL injection attempt: ${key}=${value}`);
      // Redirect to `/login` without any query parameters
      return {
        redirect: {
          destination: '/login',
          permanent: false,
        },
      };
    }
  }

  // Debugging: Log valid parameters
  console.log("Valid query parameters:", query);

  // Process valid query parameters
  const id = query.ref || null;
  const redirect = query.redirect || null;

  const response = await getLogoCompanyByqueryParams(id);

  return {
    props: {
      logo: response,
      redirect,
    },
  };
}




export default withStyles(styles)(SignIn);
