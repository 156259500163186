import {
  ERROR_AT_LOGIN,
  SET_CHANGE_PASSWORD_MODAL,
  SET_ID_USER_TRACKING,
  SET_MESSAGE_AT_FORGOT_PASSWORD,
  SET_MESSAGE_AT_RESET_PASSWORD,
  SET_USER_FROM_CLIENT,
  WILL_LOGIN,
  WILL_LOGOUT
} from '../action_types';

export const authReducer = (state, action) => {
  switch (action?.type) {
    case ERROR_AT_LOGIN:
      return { ...state, error: action.payload };
    case SET_MESSAGE_AT_FORGOT_PASSWORD: 
      return { ...state, messageAtForgotPassword: action.payload };
    case SET_MESSAGE_AT_RESET_PASSWORD:
      return { ...state, messageAtResetPassword: action.payload };
    case SET_USER_FROM_CLIENT:
      return {
        ...state, 
        isAuthenticated: true,
        email: action.payload.user.email,
        username: action.payload.user.username
      }
    case WILL_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        email: action.payload.user.email,
        username: action.payload.user.username
      };
    case WILL_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        email: '',
        username: '',
        id: null
      };
    case SET_ID_USER_TRACKING:
      return {
        ...state,
        id: action.payload
      };
    case SET_CHANGE_PASSWORD_MODAL: 
      return {
        ...state,
        showChangePasswordModal: action.payload
      }
    default:
      return state;
  };
}; 