import {
  SET_ACCOUNT_CREATED,
  SET_COMPANIES_DROPDOWN,
  SET_COMPANY_CONFIG,
  SET_CUSTOM_INPUTS,
  SET_ERROR_AT_SIGNUP_FIELD,
  SET_SIGNUP_FIELD,
  SET_TOUCHED_FIELD,
  SET_USER_TYPES
} from '../action_types';

// SIGNUP FORM IN STEPS
export const setSignupField = (dispatch, payload) => {
  dispatch({ type: SET_SIGNUP_FIELD, payload: payload });
}

// SET ERROR IN FIELD
export const setErrorAtSignupField = (dispatch, payload) => {
  dispatch({ type: SET_ERROR_AT_SIGNUP_FIELD, payload: payload });
}

export const setTouchedField = (dispatch, payload) => {
  dispatch({ type: SET_TOUCHED_FIELD, payload: payload });
}

// SET COMPANIES
export const setCompaniesDropdown = (dispatch, payload) => {
  dispatch({ type: SET_COMPANIES_DROPDOWN, payload: payload });
}

// SET USER TYPES
export const setUserTypes = (dispatch, payload) => {
  dispatch({ type: SET_USER_TYPES, payload: payload });
}

export const setCustomCompanyInputs = (dispatch, payload) => {
  dispatch({ type: SET_CUSTOM_INPUTS, payload: payload })
}

export const setCompanyConfig = (dispatch, payload) => {
  dispatch({ type: SET_COMPANY_CONFIG, payload: payload })
}

export const setAccountCreated = (dispatch) => {
  dispatch({ type: SET_ACCOUNT_CREATED });
}